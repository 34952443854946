export default {
  "container": "coD",
  "title-and-trash": "coV",
  "trash": "coL",
  "quantity-amount": "coT",
  "flex-row": "coz",
  "quantity": "coa",
  "quantity-input": "coH",
  "border-left": "coO",
  "dropdown": "com",
  "item-row": "cop",
  "unit-price": "cox",
  "price-input": "cow",
  "hidden": "coN",
  "d-none": "cov",
  "dropdown-option": "coo",
  "list-row": "con",
  "row-title": "coi",
  "row-price": "coA body-2",
  "row-internal-note": "coY",
  "with-internal-note": "coh",
  "dropdown-content": "cof",
  "select-item": "coK"
};
