export default {
  "selector": "cvj",
  "pension-selector-content": "cvW",
  "withholding-tax-selector-content": "cvu",
  "withholding-tax-selector-content-row": "coS",
  "power-select-field": "coc",
  "rate-field": "coq",
  "stamp-revenue-field": "coZ",
  "dropdown-option": "coR",
  "dropdown-content": "coQ"
};
