export default {
  "section": "ciN",
  "header-section": "civ",
  "header-title": "cio",
  "header-item": "cin",
  "item": "cii",
  "center-align": "ciA",
  "content-gap": "ciY",
  "cta-footer": "cih",
  "section-content": "cif"
};
