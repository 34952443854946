export default {
  "row": "cGO body-1",
  "active": "cGm",
  "cell": "cGp",
  "empty": "cGx",
  "ellipsis": "cGw",
  "text-secondary": "cGN",
  "transfer-type": "cGv",
  "transfer-type__icon": "cGo",
  "quick-actions": "cGn",
  "amount": "cGi",
  "cell-approve": "cGA",
  "account-select": "cGY",
  "row-compact": "cGh",
  "cell-amount": "cGf",
  "cell-content-receipt": "cGK body-1",
  "cell-date": "cGG",
  "row-sidebar": "cGr",
  "hidden": "cGb",
  "animated": "cGy",
  "fadein-item": "cGU",
  "animated-cell": "cGj",
  "note": "cGW"
};
