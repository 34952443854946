export default {
  "header": "cbD",
  "header-type": "cbV",
  "close": "cbL",
  "close-icon": "cbT",
  "header-infos": "cbz",
  "title": "cba body-1",
  "declined": "cbH",
  "canceled": "cbO",
  "approved": "cbm",
  "pending": "cbp",
  "date": "cbx body-2",
  "mileage-icon": "cbw",
  "distance-subtitle": "cbN body-2",
  "link-icon": "cbv",
  "container": "cbo",
  "picto": "cbn",
  "general": "cbi",
  "amount": "cbA",
  "calculation-container": "cbY",
  "counterparty": "cbh body-2",
  "infobox": "cbf"
};
