/* import __COLOCATED_TEMPLATE__ from './supplier-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
export default class SupplierSelector extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;
  get options() {
    return this.args.fetchSuppliersTask?.lastSuccessful?.value || [];
  }

  get selectedSupplier() {
    return this.options.find(({ id }) => id === this.args.invoice.supplierSnapshot.id);
  }

  get dropdownSelectedValue() {
    if (this.isArchived) {
      return `${this.selectedSupplier?.name} ${this.intl.t('supplier-invoices.supplier-invoice-details.supplier-information.archived-supplier-label')}`;
    }

    return this.selectedSupplier?.name;
  }

  get isArchived() {
    return this.selectedSupplier?.status === SUPPLIER_STATUSES.ARCHIVED;
  }

  @action updateSupplier({ id, iban, name, tin, email }) {
    this.args.updateSupplier('supplierSnapshot', { id, name, iban, tin, email });
    this.args.invoice.supplierSnapshot = {
      id,
      name,
      iban,
      tin,
      email,
    };
  }
}
