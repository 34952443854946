export default {
  "header-cell": "cYJ",
  "empty": "cYs",
  "header-content": "cYF caption-bold",
  "active": "cYg",
  "align-right": "cYD",
  "col-8": "cYV",
  "col-7": "cYL",
  "col-5": "cYT",
  "col-4": "cYz"
};
