export default {
  "header": "cKk",
  "header-type": "cKJ",
  "close": "cKs",
  "close-icon": "cKF",
  "header-infos": "cKg",
  "title": "cKD body-1",
  "declined": "cKV",
  "canceled": "cKL",
  "approved": "cKT",
  "pending": "cKz",
  "date": "cKa body-2",
  "mileage-icon": "cKH",
  "distance-subtitle": "cKO body-2",
  "link-icon": "cKm",
  "container": "cKp",
  "picto": "cKx",
  "general": "cKw",
  "amount": "cKN",
  "calculation-container": "cKv",
  "counterparty": "cKo body-2",
  "infobox": "cKn"
};
