export default {
  "y-axis-placeholder": "cLW",
  "y-axis-navigable": "cLu",
  "y-axis-tick": "cTS",
  "x-axis-placeholder": "cTc",
  "x-axis-navigable": "cTq",
  "x-axis-tick-wrapper": "cTZ",
  "x-axis-tick": "cTR",
  "chart-loading-renderer": "cTQ"
};
