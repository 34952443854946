export default {
  "wrapper": "cjs",
  "request-header": "cjF",
  "request": "cjg",
  "scheduled": "cjD",
  "scheduled-label": "cjV",
  "note": "cjL",
  "note-label": "cjT",
  "note-label-danger": "cjz",
  "note-content": "cja",
  "status": "cjH",
  "transfer-amount": "cjO"
};
