export default {
  "cell-content": "cjA",
  "icon": "cjY",
  "status_check-m": "cjh",
  "description": "cjf body-1",
  "from": "cjK body-2",
  "from--abort": "cjG",
  "description--abort": "cjr",
  "title": "cjb body-2"
};
