export default {
  "wrapper": "cHd",
  "header": "cHI",
  "fadeIn": "cHt",
  "header-title": "cHM title-4",
  "transactions-container": "cHP",
  "placeholder-title": "cHl",
  "transaction-item-list-container": "cHX",
  "transaction-item-list": "cHC",
  "item": "cHk"
};
