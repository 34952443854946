export default {
  "banner": "cVj",
  "inner": "cVW",
  "header": "cVu title-3",
  "content": "cLS",
  "subtitle": "cLc body-1",
  "list": "cLq",
  "item": "cLZ body-1",
  "icon": "cLR",
  "cta": "cLQ btn btn--primary",
  "spinner": "cLe",
  "illustration": "cLB",
  "image": "cLE"
};
