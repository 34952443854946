/* import __COLOCATED_TEMPLATE__ from './migration-modal.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL, productsNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import normalize from 'qonto/utils/normalize-string';
import { mapDocumentItemToProductPayload } from 'qonto/utils/products';

export default class MigrationModalComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service networkManager;
  @service toastFlashMessages;
  @service intl;
  @service sentry;
  @service segment;
  @service productsCatalog;

  @tracked searchValue;

  get selectedItemsCount() {
    return this.args.data.documentItems.filter(item => item.isSelected).length;
  }

  get areAllSelected() {
    return this.selectedItemsCount === this.args.data.documentItems.length;
  }

  get areSomeSelected() {
    return this.selectedItemsCount > 0;
  }

  get filteredItems() {
    if (!this.searchValue) return this.args.data.documentItems;

    return this.args.data.documentItems?.filter(item =>
      normalize(item.title).includes(normalize(this.searchValue))
    );
  }

  get canDismissMigration() {
    return !this.productsCatalog.isMigrationDismissed;
  }

  @action
  onSearch(value) {
    this.searchValue = value;
  }

  @action
  toggleSelectAll() {
    let areAllSelected = this.areAllSelected;
    this.args.data.documentItems.forEach(item => {
      item.isSelected = !areAllSelected;
    });
    this.segment.track('catalog_migration_all-selected', {
      source: this.args.data?.source,
    });
  }

  @action
  close() {
    this.args.close();
  }

  @action
  migrateItems() {
    let selectedItems = this.args.data.documentItems.filter(item => item.isSelected);
    this.migrateItemsTask.perform(selectedItems).catch(ignoreCancelation).catch(this.onError);
  }

  @action
  discardItems() {
    this.migrateItemsTask.perform([]).catch(ignoreCancelation).catch(this.onError);
  }

  migrateItemsTask = dropTask(async selectedItems => {
    let products = selectedItems.map(item => mapDocumentItemToProductPayload(item));

    let response = await this.networkManager.request(
      `${apiBaseURL}/${productsNamespace}/products/bulk`,
      {
        method: 'POST',
        data: JSON.stringify({
          products,
        }),
      }
    );
    this.toastFlashMessages.toastSuccess(
      this.intl.t('products.migration.toasts.success.product-saved', {
        count: response.total_count,
      })
    );
    this.close();
    this.args.data.onSuccess?.(this.args.data?.source);
  });

  @action
  onError(error) {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }
}
