export default {
  "donut-chart-container": "cTM",
  "donut-chart-wrapper": "cTP",
  "chart": "cTl",
  "donut-chart-legend-container": "cTX caption",
  "donut-chart-legend-item-empty": "cTC",
  "donut-chart-legend-bullet-empty": "cTk",
  "donut-chart-legend-bullet": "cTJ",
  "donut-chart-legend-text-empty": "cTs",
  "donut-chart-legend-text": "cTF",
  "donut-chart-custom-label-uncategorized": "cTg",
  "custom-label-uncategorized-button": "cTD body-1",
  "donut-chart-legend": "cTV",
  "donut-chart-legend-item": "cTL",
  "donut-chart-legend-other-item": "cTT",
  "donut-chart-legend-uncategorized-item": "cTz",
  "donut-chart-legend-text-name": "cTa",
  "tooltip": "cTH"
};
