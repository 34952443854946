export default {
  "invoice-sidebar": "cnN",
  "body": "cnv",
  "box": "cno",
  "box-header": "cnn",
  "footer": "cni",
  "footer-button": "cnA",
  "footer-button-tooltip": "cnY",
  "sidebar-box": "cnh",
  "border-top": "cnf",
  "row": "cnK",
  "status-container": "cnG",
  "due-date-container": "cnr",
  "greyed-out": "cnb",
  "struck-through": "cny",
  "overdue": "cnU",
  "primary-actions": "cnj cnh",
  "primary-action": "cnW",
  "danger-action": "cnu",
  "button-icon": "ciS",
  "button-label": "cic body-2",
  "related-credit-note-title": "ciq caption-bold",
  "paid-at-placeholder": "ciZ"
};
