export default {
  "row": "qXe",
  "cell": "qXB",
  "supplier": "qXE",
  "email-address": "qXd",
  "outstanding-balance": "qXI",
  "quick-actions": "qXt",
  "empty": "qXM",
  "supplier-container": "qXP",
  "supplier-avatar": "qXl",
  "grey-background": "qXX"
};
