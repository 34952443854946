export default {
  "row": "cyL",
  "active": "cyT",
  "dot": "cyz",
  "red": "cya",
  "green": "cyH",
  "yellow": "cyO",
  "status": "cym",
  "align-right": "cyp",
  "animated": "cyx",
  "fadein-item": "cyw",
  "cell": "cyN body-2",
  "cell-content": "cyv",
  "amount": "cyo body-2",
  "subtitle": "cyn caption",
  "padding-left": "cyi",
  "padding-right": "cyA",
  "note": "cyY"
};
