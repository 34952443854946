export default {
  "header-cell": "qqs",
  "header-content": "qqF caption-bold",
  "active": "qqg",
  "empty": "qqD",
  "align-right": "qqV",
  "request-type": "qqL",
  "requester": "qqT",
  "amount": "qqz"
};
