export default {
  "request-expense-report-validation": "cuf",
  "account-selector": "cuK",
  "has-error": "cuG",
  "actions": "cur",
  "action-btn": "cub",
  "action-btn-success": "cuy",
  "warnings": "cuU",
  "top-border": "cuj",
  "account-selector-subtitle": "cuW",
  "action-btn-right": "cuu",
  "funds-disclaimer": "qSS",
  "account-options": "qSc",
  "account-balance": "qSq"
};
