export default {
  "item": "qPm",
  "details": "qPp",
  "flex": "qPx",
  "header": "qPw qPx",
  "flex-no-gap": "qPN qPx",
  "file-name": "qPv",
  "label": "qPo body-2",
  "self-icon": "qPn",
  "with-separator": "qPi",
  "dimmed": "qPA"
};
