export default {
  "credit-note-sidebar": "cNt",
  "body": "cNM",
  "box": "cNP",
  "box-header": "cNl",
  "footer": "cNX",
  "footer-button": "cNC",
  "sidebar-box": "cNk",
  "border-top": "cNJ",
  "row": "cNs",
  "related-invoice-title": "cNF caption-bold",
  "einvoice-rejected": "cNg"
};
