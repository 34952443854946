export default {
  "row": "cVz",
  "terminated": "cVa",
  "status-avatar": "cVH",
  "amount": "cVO body-1",
  "item-info": "cVm",
  "date-cell": "cVp",
  "cell": "cVx body-2",
  "status-icon": "cVw",
  "amount-cell": "cVN",
  "item-name-cell": "cVv",
  "item-name-container": "cVo"
};
