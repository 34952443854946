export default {
  "notifications-settings": "cDN",
  "opened": "cDv",
  "icon": "cDo",
  "header": "cDn",
  "header-toggle": "cDi",
  "avatar-image": "cDA",
  "title": "cDY",
  "toggle": "cDh"
};
