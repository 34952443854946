export default {
  "header": "crD",
  "header-type": "crV",
  "close": "crL",
  "close-icon": "crT",
  "header-infos": "crz",
  "title": "cra body-1",
  "declined": "crH",
  "canceled": "crO",
  "approved": "crm",
  "pending": "crp",
  "date": "crx body-2",
  "container": "crw",
  "picto": "crN",
  "picto-status": "crv",
  "general": "cro",
  "amount": "crn",
  "counterparty": "cri body-2",
  "initiator": "crA",
  "initiator-avatar": "crY",
  "avatar-image": "crh",
  "initiator-id": "crf",
  "name": "crK body-2",
  "role": "crG caption-bold",
  "infobox": "crr",
  "account-infobox": "crb crr",
  "beneficiary-warning": "cry caption-bold",
  "dates-wrapper": "crU",
  "dates": "crj",
  "recurrence": "crW caption-bold",
  "arrow": "cru",
  "notify-checkbox": "cbS"
};
