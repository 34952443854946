export default {
  "row": "cxe",
  "cell": "cxB",
  "title": "cxE",
  "internal-note": "cxd",
  "unit": "cxI",
  "price": "cxt",
  "quick-actions": "cxM",
  "empty": "cxP",
  "checkbox": "cxl"
};
