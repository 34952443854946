export default {
  "header-cell": "qlU",
  "first-col": "qlj",
  "mid-col": "qlW",
  "outstanding-col": "qlu",
  "header-content": "qXS caption-bold",
  "active": "qXc",
  "quick-actions": "qXq",
  "empty": "qXZ"
};
