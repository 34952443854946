export default {
  "navigation-dropdown": "cDe",
  "expanded": "cDB",
  "trigger": "cDE",
  "trigger-title-icon": "cDd",
  "collapsed": "cDI",
  "responsive": "cDt",
  "logo": "cDM",
  "active": "cDP",
  "trigger-title": "cDl",
  "content": "cDX",
  "content-container": "cDC",
  "dropdown-icon": "cDk",
  "link": "cDJ"
};
