export default {
  "header": "qcB",
  "header-type": "qcE",
  "close": "qcd",
  "close-icon": "qcI",
  "header-infos": "qct",
  "title": "qcM body-1",
  "declined": "qcP",
  "canceled": "qcl",
  "approved": "qcX",
  "date": "qcC body-2",
  "container": "qck",
  "picto": "qcJ",
  "request": "qcs",
  "picto-status": "qcF",
  "check": "qcg",
  "stop": "qcD",
  "warning": "qcV",
  "general": "qcL",
  "amount": "qcT",
  "counterparty": "qcz body-2",
  "initiator": "qca",
  "initiator-avatar": "qcH",
  "avatar-image": "qcO",
  "initiator-id": "qcm",
  "name": "qcp body-2",
  "role": "qcx caption-bold",
  "infobox": "qcw",
  "account-infobox": "qcN qcw",
  "beneficiary-warning": "qcv caption-bold",
  "dates-wrapper": "qco",
  "dates": "qcn",
  "recurrence": "qci caption-bold",
  "arrow": "qcA",
  "notify-checkbox": "qcY"
};
