export default {
  "header-cell": "cyt",
  "empty": "cyM",
  "header-content": "cyP caption-bold",
  "active": "cyl",
  "header-type": "cyX",
  "header-reason": "cyC",
  "header-status": "cyk",
  "header-amount": "cyJ",
  "row-sidebar": "cys",
  "hidden": "cyF",
  "animated": "cyg",
  "fadein-item": "cyD",
  "animated-cell": "cyV"
};
