export default {
  "card": "cNh",
  "error": "cNf",
  "customer-info": "cNK",
  "edit-customer-tooltip": "cNG",
  "title": "cNr",
  "flex-row": "cNb",
  "dot-separator": "cNy",
  "flex-span": "cNU",
  "faq-link": "cNj",
  "tooltip-wrapper": "cNW"
};
