export default {
  "header-cell": "qqN",
  "header-content": "qqv caption-bold",
  "active": "qqo",
  "empty": "qqn",
  "request-type": "qqi",
  "status": "qqA",
  "note": "qqY",
  "amount": "qqh"
};
