export default {
  "referral-invite-new": "cYU",
  "referral": "cYj",
  "center": "cYW",
  "illustration": "cYu",
  "share-referral": "chS",
  "choice": "chc",
  "line": "chq",
  "email": "chZ",
  "email-input": "chR",
  "input-field--error": "chQ",
  "error-message": "che",
  "external-link-icon": "chB"
};
