export default {
  "company-logo": "cDL",
  "list-item": "cDT",
  "link": "cDz",
  "title-wrapper": "cDa",
  "title": "cDH body-2",
  "subtitle": "cDO caption",
  "truncated-text": "cDm",
  "image-placeholder": "cDp"
};
