export default {
  "header-cell": "qCa",
  "header-content": "qCH caption-bold",
  "active": "qCO",
  "empty": "qCm",
  "align-right": "qCp",
  "type": "qCx",
  "requester": "qCw",
  "request-date": "qCN",
  "amount": "qCv",
  "status": "qCo"
};
