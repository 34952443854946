/* import __COLOCATED_TEMPLATE__ from './amount-with-reference.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class RequestTransferAmountWithReference extends Component {
  @tracked isAmountFieldDirty = false;

  get shouldDisplayAmountValidationErrorMessage() {
    return this.isAmountFieldDirty || this.args.enableTransferValidations;
  }

  @action updateAmount(amount) {
    this.args.requestTransfer.set('amount', amount);
    this.isAmountFieldDirty = true;
  }

  @action
  preventScroll(event) {
    event.preventDefault();
  }
}
