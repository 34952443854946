export default {
  "wrapper": "caa",
  "header": "caH",
  "fadeIn": "caO",
  "header-content": "cam",
  "with-cta": "cap",
  "header-title": "cax title-4",
  "advanced-filters-trigger": "caw",
  "filters-button": "caN",
  "filters-button-upsell": "cav",
  "filters-upsell-item": "cao",
  "filters-badge": "can",
  "chart-container": "cai",
  "chart-legend-container": "caA mb-24",
  "chart-watermark-container": "caY",
  "chart-watermark": "cah",
  "placeholder-title": "caf",
  "placeholder-subtitle": "caK",
  "advanced-filters": "caG"
};
