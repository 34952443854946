export default {
  "invoice-sidebar": "cil",
  "body": "ciX",
  "box": "ciC",
  "box-header": "cik",
  "footer": "ciJ",
  "footer-button": "cis",
  "sidebar-box": "ciF",
  "border-top": "cig",
  "row": "ciD",
  "status-container": "ciV",
  "due-date-container": "ciL",
  "mark-as-btn": "ciT",
  "primary-actions": "ciz ciF",
  "primary-action": "cia body-1",
  "button-icon": "ciH"
};
