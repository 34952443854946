export default {
  "custom-period-wrapper": "czZ",
  "fadeIn": "czR",
  "custom-period-description": "czQ body-2",
  "header": "cze",
  "arrow-left": "czB",
  "body": "czE",
  "period-wrapper": "czd",
  "back-button": "czI",
  "footer": "czt"
};
