export default {
  "card": "cmH",
  "card-primary": "cmO",
  "content": "cmm",
  "content-primary": "cmp",
  "disabled": "cmx",
  "illustration": "cmw",
  "illustration-primary": "cmN",
  "illustration-disabled": "cmv",
  "cta": "cmo",
  "dismiss-button": "cmn",
  "buttons": "cmi",
  "required-badge": "cmA"
};
