export default {
  "wrapper": "chW",
  "tabs": "chu",
  "tabs-nav": "cfS",
  "tabs-panel-wrapper": "cfc",
  "list-head": "cfq",
  "list": "cfZ",
  "footer": "cfR",
  "reward-amount": "cfQ",
  "earned": "cfe",
  "pending": "cfB",
  "empty-state-wrapper": "cfE",
  "empty-state": "cfd",
  "illustration": "cfI",
  "error-wrapper": "cft",
  "error-illustration": "cfM",
  "error-description": "cfP",
  "spinner": "cfl",
  "total-earned": "cfX"
};
