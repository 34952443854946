export default {
  "item-status": "car",
  "item-date": "cab",
  "item-amount": "cay body-1",
  "item-amount-credit": "caU",
  "row": "caj",
  "icon": "caW",
  "disabled": "cau",
  "icon-container": "cHS",
  "text-container": "cHc",
  "text-row": "cHq",
  "item-details": "cHZ",
  "counterparty-name": "cHR",
  "column": "cHQ",
  "item-amount-disabled": "cHe",
  "attachment-tooltip": "cHB",
  "bank-account-avatar": "cHE"
};
