export default {
  "numbering-container": "coe",
  "automatic-number-label": "coB",
  "icon-tooltip": "coE",
  "fix-width": "cod",
  "dates-container": "coI",
  "with-performance-date": "cot",
  "date-picker": "coM",
  "label": "coP",
  "header-text-field-container": "col",
  "mt-16": "coX",
  "header-text-field": "coC",
  "header-text-field-close-button": "cok"
};
