export default {
  "row": "qCy",
  "active": "qCU",
  "dot": "qCj",
  "red": "qCW",
  "green": "qCu",
  "yellow": "qkS",
  "align-right": "qkc",
  "animated": "qkq",
  "fadein-item": "qkZ",
  "cell": "qkR body-2",
  "cell-content": "qkQ",
  "amount": "qke body-1",
  "subtitle": "qkB caption",
  "status": "qkE",
  "cell-amount": "qkd"
};
