export default {
  "row": "qdb",
  "disabled": "qdy",
  "cell": "qdU body-2",
  "cell-link": "qdj",
  "cell-content": "qdW",
  "item-truncate-text": "qdu",
  "item-name-cell": "qIS",
  "item-account-cell": "qIc",
  "icon": "qIq",
  "icon-download": "qIZ"
};
