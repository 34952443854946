export default {
  "container": "cOM",
  "label": "cOP",
  "disabled": "cOl",
  "small": "cOX caption",
  "large": "cOC body-2",
  "caption": "cOk",
  "amount": "cOJ",
  "right-aligned": "cOs",
  "expanded": "cOF",
  "amount--current": "cOg",
  "amount--total": "cOD",
  "amount--sep": "cOV"
};
