/* import __COLOCATED_TEMPLATE__ from './archive-popup.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ArchivePopup extends Component {
  @service intl;
  @service router;
  @service sentry;
  @service toastFlashMessages;

  archiveTask = dropTask(async close => {
    let { supplier } = this.args.data;
    try {
      await supplier.archive();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-list.archived-suplier.success-toast')
      );
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
    } finally {
      this.router.transitionTo('suppliers');
      close();
    }
  });
}
