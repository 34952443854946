export default {
  "subtitle": "cUX",
  "green-text": "cUC",
  "balance-subtitle": "cUk",
  "options": "cUJ",
  "shadow": "cUs",
  "menu-item": "cUF body-2",
  "close-account": "cUg",
  "footer": "cUD",
  "progress": "cUV",
  "progress-bar": "cUL",
  "progress-bar-text": "cUT",
  "progress-active": "cUz"
};
