export default {
  "container": "ciK",
  "form-container": "ciG",
  "content": "cir",
  "fields": "cib",
  "input-settings": "ciy",
  "current-year": "ciU",
  "next-invoice-number": "cij",
  "preview-container": "ciW",
  "actions": "ciu",
  "close-button": "cAS btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cAc",
  "preview-image": "cAq"
};
