export default {
  "sidepanel": "qlm",
  "close-icon": "qlp",
  "close-button": "qlx",
  "supplier-card": "qlw",
  "supplier-card-details": "qlN",
  "supplier-card-placeholder": "qlv",
  "box": "qlo",
  "box-element": "qln",
  "related-invoices-title": "qli",
  "placeholder-container": "qlA",
  "related-invoices-wrapper": "qlY",
  "related-invoice-placeholder": "qlh",
  "related-invoice-placeholder-inner": "qlf",
  "actions": "qlK"
};
