/* import __COLOCATED_TEMPLATE__ from './sidebar-actions.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { bool } from 'macro-decorators';

import { STATUS } from 'qonto/constants/requests';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { SPEND_LIMIT_TYPES, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { SPEND_LIMITS_WARNINGS } from 'qonto/utils/extract-confirmation-response';
import { prepareLimitsData } from 'qonto/utils/transfers';

export default class SidebarActionsComponent extends Component {
  @service modals;
  @service router;
  @service toastFlashMessages;
  @service abilities;
  @service intl;
  @service segment;
  @service flowLinkManager;
  @service beneficiariesManager;
  @service organizationManager;

  @bool('organizationManager.organization.hasInternationalOutFeature') hasInternationalOutFeature;

  get shouldDisplaySubmitKycDisclaimer() {
    return (
      this.organizationManager.membership.shouldSubmitKyc &&
      this.organizationManager.organization.kybPending
    );
  }

  get showCTA() {
    return (
      this.abilities.can('update supplierInvoice') &&
      [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(this.args.invoice?.status)
    );
  }

  get hasTransferWarnings() {
    let { warnings, market } = this.args.transferLimits || {};

    return (
      warnings?.some(warning => SPEND_LIMITS_WARNINGS.includes(warning)) || market?.opened === false
    );
  }

  get canPayByTransfer() {
    return (
      this.abilities.can('create transfer') && (!this.hasTransferWarnings || this.args.isSwift)
    );
  }

  get canRequestTransfer() {
    return this.abilities.can('request transfer supplierInvoice') && !this.args.isSwift;
  }

  get showDeclinedNote() {
    let { invoice, request } = this.args;

    return (
      [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(invoice?.status) &&
      request?.status === STATUS.DECLINED
    );
  }

  get showSwiftDisclaimers() {
    return this.args.isSwift && this.hasTransferWarnings;
  }

  get swiftDisclaimer() {
    if (this.args.transferLimits) {
      let { warnings, spendLimits, market } = this.args.transferLimits;

      if (warnings) {
        let { aboveLimitType, monthly, monthSpendings, perTransfer } = prepareLimitsData(
          warnings,
          spendLimits
        );

        if (market?.opened === false) {
          return this.intl.t('supplier-invoices.cta.request-transfer-tooltip.swift-unavailable');
        }

        return aboveLimitType === SPEND_LIMIT_TYPES.MONTHLY
          ? this.intl.t('supplier-invoices.cta.request-transfer-tooltip.monthly-limit', {
              balance_monthly_transfer_limit: monthly - monthSpendings,
            })
          : this.intl.t('supplier-invoices.cta.request-transfer-tooltip.per-transfer-limit', {
              per_transfer_limit: perTransfer,
            });
      }
    }
  }

  openTransactionsModalTask = dropTask(async () => {
    let { invoice, submitForm } = this.args;

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    this.segment.track('supplier-invoices_mark-as-paid_clicked', {
      tab: invoice.status,
      is_non_financial_document: invoice.isAttachmentNonFinancial,
    });

    this.modals.open(
      'supplier-invoices/transactions-modal',
      {
        isFullScreenModal: true,
        attachment: invoice.attachment,
        invoice,
        onCloseModal: () => {
          this.router.transitionTo('supplier-invoices.index');
          this.toastFlashMessages.toastSuccess(
            this.intl.t('supplier-invoices.success-toast.mark-as-paid')
          );
        },
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  });

  payByTransfer = dropTask(async () => {
    let { invoice, submitForm } = this.args;

    let queryParams = {
      origin: TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
      supplierInvoiceId: invoice.id,
    };

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    this.segment.track('supplier-invoices_pay-by-transfer_clicked', {
      is_non_financial_document: invoice.isAttachmentNonFinancial,
    });

    let isFx = invoice.totalAmount && invoice.totalAmount.currency !== 'EUR';
    if (isFx) {
      // If the organization has access to the revamped international transfer flow,
      // we don't want to transition to the deprecated flow.
      // It should not reach this point, as the CTA should be disabled,
      // but we're adding this check as a safety net.
      if (!this.hasInternationalOutFeature) {
        this.flowLinkManager.transitionTo({
          name: 'fx-transfer',
          stepId: 'beneficiaries',
          queryParams,
        });
      }
    } else {
      let initialStepId = await this._getSEPATransferInitialStepId(invoice);
      this.flowLinkManager.transitionTo({
        name: 'sepa-transfer',
        stepId: initialStepId,
        queryParams,
      });
    }
  });

  requestTransfer = dropTask(async () => {
    let { invoice, submitForm } = this.args;

    if (submitForm) {
      let submitted = await submitForm.perform();
      if (!submitted) return;
    }

    this.segment.track('supplier-invoices_request-transfer_clicked');
    this.router.transitionTo('requests.transfers.new', {
      queryParams: {
        supplierInvoiceId: invoice.id,
      },
    });
  });

  async _getSEPATransferInitialStepId(invoice) {
    let { iban, supplierName, supplierSnapshot } = invoice || {};

    if (iban || supplierSnapshot?.iban) {
      let beneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
        this.organizationManager.organization.id,
        iban || supplierSnapshot.iban
      );
      if (beneficiary && beneficiary.name === (supplierName || supplierSnapshot?.name))
        return 'details';
    }

    return 'add-supplier';
  }
}
