export default {
  "wrapper": "cUa",
  "card-header": "cUH",
  "subtitle-caption": "cUO",
  "estimated-earnings": "cUm",
  "progress": "cUp",
  "progress-bar": "cUx",
  "progress-bar-text": "cUw",
  "subtitle": "cUN",
  "badge": "cUv",
  "core-information": "cUo",
  "secondary-information": "cUn",
  "remuneration-table": "cUi",
  "name": "cUA title-3",
  "separator": "cUY",
  "remuneration-line": "cUh",
  "german-disclaimer": "cUf",
  "table": "cUK",
  "table-right": "cUG"
};
