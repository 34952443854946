export default {
  "row": "cfK",
  "icon": "cfG",
  "cell": "cfr",
  "cell-amount": "cfb",
  "cell-status": "cfy",
  "cell-date": "cfU",
  "cell-content": "cfj",
  "align-right": "cfW"
};
