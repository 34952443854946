export default {
  "sections": "qRl",
  "actions": "qRX",
  "search-input": "qRC",
  "no-upload": "qRk",
  "new-link": "qRJ body-2",
  "between-links": "qRs",
  "action-bar": "qRF",
  "empty-state": "qRg"
};
