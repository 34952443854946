export default {
  "modal": "qPD",
  "action-container": "qPV",
  "similar-label": "qPL",
  "info-icon": "qPT",
  "container": "qPz",
  "subtitle": "qPa caption-bold",
  "error": "qPH",
  "item-container": "qPO"
};
