export default {
  "banner": "cLx",
  "content": "cLw",
  "title-row": "cLN",
  "title": "cLv title-3",
  "badge": "cLo",
  "text": "cLn body-2",
  "missing-info-disclaimer": "cLi",
  "button": "cLA btn btn--primary",
  "spinner": "cLY",
  "image": "cLh"
};
