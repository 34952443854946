export default {
  "review": "qew",
  "review-content": "qeN",
  "review-parameters": "qev",
  "row": "qeo",
  "row__border": "qen",
  "label": "qei",
  "value": "qeA",
  "error": "qeY",
  "tooltip-icon": "qeh",
  "terms-link": "qef"
};
