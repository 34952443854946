export default {
  "row": "qqf",
  "active": "qqK",
  "dot": "qqG",
  "red": "qqr",
  "green": "qqb",
  "yellow": "qqy",
  "status": "qqU",
  "align-right": "qqj",
  "animated": "qqW",
  "fadein-item": "qqu",
  "cell": "qZS body-2",
  "cell-content": "qZc",
  "amount": "qZq body-1",
  "subtitle": "qZZ caption",
  "no-padding": "qZR"
};
