export default {
  "row": "qZQ",
  "active": "qZe",
  "animated": "qZB",
  "fadein-item": "qZE",
  "cell": "qZd body-2",
  "cell-content": "qZI",
  "subtitle": "qZt caption",
  "no-padding": "qZM",
  "note": "qZP",
  "cell-amount": "qZl",
  "cell-content-amount": "qZX body-1"
};
