export default {
  "section-container": "cKe",
  "disclaimer-section": "cKB",
  "trip-title": "cKE caption-bold mb-16",
  "google-link-container": "cKd",
  "link-icon": "cKI",
  "trip-details": "cKt body-2",
  "trip-details-icon": "cKM",
  "trip-details-title": "cKP",
  "subtitle": "cKl caption-bold mb-16",
  "disclaimer": "cKX",
  "visualizer": "cKC"
};
