export default {
  "card": "cLV",
  "header": "cLL",
  "avatar": "cLT",
  "title": "cLz",
  "subtitle": "cLa",
  "label": "cLH",
  "item": "cLO body-2",
  "header-icon": "cLm",
  "mt-8": "cLp"
};
