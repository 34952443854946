export default {
  "bank-account-option": "qkI",
  "row": "qkt",
  "active": "qkM",
  "animated": "qkP",
  "fadein-item": "qkl",
  "cell": "qkX body-2",
  "cell-content": "qkC",
  "subtitle": "qkk caption",
  "no-padding": "qkJ",
  "empty": "qks",
  "note": "qkF",
  "cell-content-amount": "qkg body-1",
  "cell-content-receipt": "qkD body-1",
  "cell-quick-actions": "qkV",
  "account-selector": "qkL",
  "cell-type": "qkT"
};
