export default {
  "invoice-sidebar": "qEF",
  "body": "qEg",
  "box": "qED",
  "box-header": "qEV",
  "sidebar-box": "qEL",
  "border-top": "qET",
  "row": "qEz",
  "greyed-out": "qEa",
  "strike-through": "qEH"
};
