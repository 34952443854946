/* import __COLOCATED_TEMPLATE__ from './detailed-fees.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

export default class SubscritionDetailedFeesComponent extends Component {
  disclaimerBlock = Disclaimer.Block;

  @service intl;
  @service organizationManager;

  @reads('organizationManager.organization') organization;

  fees = {
    sepa_transfers_and_sdds: {
      icon: 'consumption-transfer',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.sepa_transfers_and_sdds.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.sepa_transfers_and_sdds.subtitle',
          options
        );
      },
    },
    swift_incomes: {
      icon: 'icon_arrow_bottom_outlined',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.swift_incomes.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.swift_incomes.subtitle',
          options
        );
      },
    },
    fx_transfers: {
      icon: 'icon_arrow_top_outlined',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.fx_transfers.title'
      ),
      subtitle: (options, { isAmountAndPercentUnit }) => {
        if (isAmountAndPercentUnit) {
          return this.intl.t(
            'subscription.consumption-table.plan-overview.details-fees.features.fx_transfers.subtitle-fees',
            { ...options, fees: options.price }
          );
        }
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.fx_transfers.subtitle-generic',
          options
        );
      },
    },
    one_cards: {
      icon: 'icon_card_outlined',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.one_cards.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.one_cards.subtitle',
          options
        );
      },
    },
    one_cards_atm: {
      icon: 'icon_nav_atm',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.one_cards_atm.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.one_cards_atm.subtitle',
          options
        );
      },
    },
    plus_cards: {
      icon: 'icon_card_outlined',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.plus_cards.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.plus_cards.subtitle',
          options
        );
      },
    },
    plus_cards_atm: {
      icon: 'icon_nav_atm',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.plus_cards_atm.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.plus_cards_atm.subtitle',
          options
        );
      },
    },
    metal_cards: {
      icon: 'icon_card_outlined',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.metal_cards.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.metal_cards.subtitle',
          options
        );
      },
    },
    virtual_cards: {
      icon: 'consumption-virtual-card',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.virtual_cards.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.virtual_cards.subtitle',
          options
        );
      },
    },
    checks: {
      icon: 'icon_check',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.check_deposits.title'
      ),
      subtitle: (options, { hasIncludedChecks }) => {
        if (hasIncludedChecks) {
          return this.intl.t(
            'subscription.consumption-table.plan-overview.details-fees.features.check_deposits.subtitle.other-plans',
            options
          );
        }
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.check_deposits.subtitle.basic-plan',
          options
        );
      },
    },
    direct_debit_collection_send: {
      icon: 'consumption-incoming-direct-debits',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.incoming_direct_debits.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.incoming_direct_debits.subtitle',
          options
        );
      },
    },
    memberships: {
      icon: 'icon_user_outlined',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.memberships.title'
      ),
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.memberships.subtitle',
          options
        );
      },
    },
    other_fees: {
      icon: 'consumption-invoice',
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.other_fees.title'
      ),
      subtitle: () => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.other_fees.subtitle'
        );
      },
    },
  };

  get detailedFeesList() {
    // Returns an array of fee items to be used in DetailedFees component
    return this.args.additionalFees.map(fee => {
      let { code, totalAmount } = fee;
      let { title, icon } = this.fees[code];
      let { value: totalFee, currency } = totalAmount;
      let subtitle = this.getSubtitleFor(fee);

      return { code, icon, currency, title, totalFee, subtitle };
    });
  }

  getSubtitleFor(fee) {
    let {
      code,
      extraItemAmount,
      extraItemUnit,
      extraItemAmountPercent: rate,
      consumedNumber,
      limitNumber,
    } = fee;

    let options = {
      count: consumedNumber,
      limitIncluded: limitNumber,
      price: extraItemAmount?.value
        ? this.intl.formatNumber(extraItemAmount.value, {
            currency: extraItemAmount.currency,
            style: 'currency',
          })
        : null,
      countLessThanLimit: consumedNumber < limitNumber,
      rate,
    };

    let conditions = {
      hasIncludedChecks: limitNumber > 0,
      isAmountAndPercentUnit: extraItemUnit === 'amount_and_percent',
    };

    return this.fees[code].subtitle(options, conditions);
  }
}
