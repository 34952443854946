export default {
  "progress-container": "qJI",
  "dashed-line": "qJt",
  "solid-line": "qJM",
  "circle-container": "qJP",
  "circle": "qJl",
  "current-step-dot": "qJX",
  "last-step-dot": "qJC",
  "success-icon": "qJk",
  "rejected-icon": "qJJ"
};
