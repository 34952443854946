export default {
  "account-description": "cOy",
  "account-identification": "cOU",
  "account-avatar": "cOj",
  "account-name": "cOW",
  "account-balance": "cOu body-2",
  "status": "cmS",
  "external-accounts-disclaimer": "cmc",
  "failed-connection": "cmq"
};
