export default {
  "row": "cYO",
  "cell": "cYm",
  "greyed-out": "cYp",
  "struck-through": "cYx",
  "flex-cell": "cYw",
  "overdue": "cYN",
  "align-right": "cYv",
  "customer-name-container": "cYo",
  "icon": "cYn",
  "warning": "cYi",
  "with-separator": "cYA",
  "align-center": "cYY"
};
