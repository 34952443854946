export default {
  "header-cell": "qCn",
  "header-content": "qCi caption-bold",
  "active": "qCA",
  "empty": "qCY",
  "type": "qCh",
  "requester": "qCf",
  "request-date": "qCK",
  "amount": "qCG",
  "receipt": "qCr",
  "quick-actions": "qCb"
};
