export default {
  "row": "qZC",
  "active": "qZk",
  "dot": "qZJ",
  "red": "qZs",
  "green": "qZF",
  "yellow": "qZg",
  "status": "qZD",
  "align-right": "qZV",
  "animated": "qZL",
  "fadein-item": "qZT",
  "cell": "qZz body-2",
  "cell-content": "qZa",
  "amount": "qZH body-2",
  "subtitle": "qZO caption",
  "padding-left": "qZm",
  "padding-right": "qZp",
  "note": "qZx"
};
