/* import __COLOCATED_TEMPLATE__ from './pending.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

import { ensureSafeComponent } from '@embroider/util';
import { Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { REQUEST_ORIGINS, REQUEST_TYPES } from 'qonto/constants/requests';
import { Member } from 'qonto/react/components/tasks/table/cell/member';

export default class TaskTableItemPendingComponent extends Component {
  spinner = Spinner;

  @service modals;
  @service attachmentsManager;
  @service organizationManager;
  @service intl;
  @service segment;
  member = Member;

  get activeAccounts() {
    return this.organizationManager.organization?.activeAccounts;
  }

  get requestComponentName() {
    let type = dasherize(this.args.request.requestType);
    return ensureSafeComponent(`tasks/table/cell/${type}`, this);
  }

  get approveTooltipMessage() {
    let { request } = this.args;
    let { requestType } = request;

    if ([REQUEST_TYPES.EXPENSE_REPORT, REQUEST_TYPES.MILEAGE].includes(requestType)) {
      if (!request.lastStep) {
        return this.intl.t('approval-workflows.requests.tooltip.approve');
      }
      return this.intl.t('reimbursements.tooltip.pay-by-transfer');
    }

    if ([REQUEST_TYPES.FLASH_CARD, REQUEST_TYPES.VIRTUAL_CARD].includes(requestType)) {
      return this.intl.t('btn.cards.approve-request');
    }

    if ([REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION].includes(requestType)) {
      return this.intl.t('requests.sdd-collections.review.approve.tooltip');
    }

    return this.intl.t('transfers.requests.quick-approve-tooltip');
  }

  get rejectTooltipMessage() {
    let { request } = this.args;
    let initiatorId = request.get?.('initiator.id') || request.initiator.id;

    if (initiatorId === this.organizationManager.membership?.id) {
      return this.intl.t('reimbursements.tooltip.cancel');
    }

    return this.intl.t('reimbursements.tooltip.reject');
  }

  handleOpenFilePreviewModal = dropTask(async event => {
    event.stopPropagation();
    await this.args.request.get('attachments');

    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: this.args.request.attachmentsFiles[0],
      model: this.args.request,
      download: this.attachmentsManager.downloadAttachment,
    });
  });

  get showAttachment() {
    return this.args.request.attachmentIds?.length > 0;
  }

  @action
  handleSelectAccount(bankAccount) {
    this.segment.track('bank_account_picker_opened', {
      origin: REQUEST_ORIGINS.tasks,
    });
    this.args.onAccountChange(this.args.request, bankAccount);
  }

  @action
  stopPropagation(event) {
    event.stopPropagation();
  }

  handleRequestApprovalTask = dropTask(async () => {
    await this.args.onApprove(this.args.request);
  });

  handleRequestRejectionTask = dropTask(async () => {
    await this.args.onReject(this.args.request);
  });
}
