export default {
  "savings-account": "qQb",
  "closed": "qQy",
  "badge": "qQU",
  "subtitle": "qQj",
  "amount": "qQW",
  "processing": "qQu",
  "gain": "qeS",
  "active": "qec",
  "progress": "qeq",
  "progress-bar": "qeZ",
  "progress-text": "qeR",
  "info": "qeQ",
  "menu-item": "qee body-2",
  "close-account": "qeB"
};
