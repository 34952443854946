export default {
  "wrapper": "cxp",
  "per-page-container": "cxx",
  "options": "cxw",
  "option": "cxN",
  "active": "cxv",
  "description": "cxo",
  "description-per-page": "cxn",
  "summary": "cxi"
};
