export default {
  "header": "qIy",
  "subtitle": "qIU body-2",
  "date": "qIj",
  "subtitle-error": "qIW",
  "card": "qIu",
  "card-header": "qtS",
  "line-placeholder": "qtc",
  "post-closing-list": "qtq",
  "post-closing-container": "qtZ",
  "post-closing-list-element": "qtR",
  "free-trial-disclaimer": "qtQ",
  "description": "qte body-2",
  "mw-100": "qtB",
  "addon-overview": "qtE",
  "addons-container": "qtd"
};
