export default {
  "card-sidebar-header": "cWD",
  "content": "cWV",
  "header-type": "cWL",
  "close": "cWT",
  "close-icon": "cWz",
  "header-infos": "cWa",
  "title": "cWH body-1",
  "danger": "cWO",
  "success": "cWm",
  "warning": "cWp",
  "date": "cWx body-2",
  "card-image-container": "cWw",
  "card-image": "cWN",
  "info-container": "cWv",
  "spending-reason": "cWo",
  "spending-reason-label": "cWn body-2 mr-8",
  "spending-reason-note": "cWi body-2"
};
