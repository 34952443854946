export default {
  "supplier-selector-wrapper": "qMa",
  "select-supplier": "qMH",
  "iban": "qMO",
  "card": "qMm body-2",
  "add-btn": "qMp btn btn--square btn--icon-only",
  "archived-card": "qMx",
  "title": "qMw body-1",
  "archived-title": "qMN",
  "edit-supplier-tooltip": "qMv",
  "add-supplier": "qMo btn btn--tertiary",
  "error": "qMn caption"
};
