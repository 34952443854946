export default {
  "cell": "qlB",
  "cell-label-container": "qlE",
  "cell-label": "qld",
  "overflow": "qlI",
  "sub-label": "qlt body-2",
  "overdue": "qlM",
  "file-name-warning": "qlP",
  "missing": "qll",
  "warning-icon": "qlX",
  "flex-label": "qlC",
  "with-separator": "qlk",
  "self-invoice-icon": "qlJ"
};
