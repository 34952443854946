/* import __COLOCATED_TEMPLATE__ from './client-form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import COUNTRIES_TO_LOCALES_ARRAY, {
  DEFAULT_LOCALE_CODE,
} from 'qonto/constants/countries-to-locales';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class InvoicesClientFormComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;
  @service modals;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service store;
  @service toastFlashMessages;

  @tracked isValidationEnabled = false;
  @tracked isDeliveryAddressValidationEnabled = false;

  willDestroy() {
    super.willDestroy(...arguments);

    // Ember Data 3.28 does not like calling `unloadRecord()` on a model
    // instance that is already in "destroying" mode. Since we can skip the
    // cleanup in this case anyway we just return early here.
    if (this.client.isDestroying || this.client.isDestroyed) return;

    this.client.rollbackAttributes();

    if (this.client.isNew) {
      this.client.unloadRecord();
    }
  }

  get client() {
    return this.args.data.client;
  }

  get isEdit() {
    return this.args.data.isEdit;
  }

  get title() {
    return this.intl.t(
      this.isEdit
        ? 'receivable-invoices.customer-edition.title'
        : 'receivable-invoices.customer-creation.title'
    );
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get isCompany() {
    return this.client.kind === 'company';
  }

  get isFreelancer() {
    return this.client.kind === 'freelancer';
  }

  get isIndividual() {
    return this.client.kind === 'individual';
  }

  get showLegalInformation() {
    return !this.isIndividual || this.italianIndividual;
  }

  get italianIndividual() {
    return this.isIndividual && this.client.billingAddress?.countryCode === 'IT';
  }

  get italianCompany() {
    return !this.isIndividual && this.client.billingAddress?.countryCode === 'IT';
  }

  get isTINOptional() {
    let countryCode = this.client.billingAddress?.countryCode;
    return (
      (countryCode === 'DE' && this.isCompany) ||
      (this.organization.legalCountry === 'IT' && this.isFreelancer && countryCode !== 'IT')
    );
  }

  get selectedCountry() {
    if (['DE', 'ES', 'FR', 'IT'].includes(this.client.get('billingAddress.countryCode'))) {
      return this.client.billingAddress.countryCode;
    }
    return 'other';
  }

  get showFrenchDisclaimer() {
    let areBothPartiesFrench = [this.selectedCountry, this.organization.legalCountry].every(
      countryCode => countryCode === 'FR'
    );

    let isSelectedLanguageNotFrench = this.client.localeObject?.code !== 'fr';

    return areBothPartiesFrench && isSelectedLanguageNotFrench;
  }

  @action updateClientLocale(localeObject) {
    this.client.localeObject = localeObject;
    this.segment.track('invoice-creation_client_language-selected', {
      country: this.client.billingAddress.countryCode,
      language: localeObject.name,
    });
  }

  get cancelModalTitle() {
    return this.intl.t(
      this.isEdit
        ? 'receivable-invoices.customer-edition.exit-modal.title'
        : 'receivable-invoices.customer-creation.exit-modal.title'
    );
  }

  get cancelModalDescription() {
    return this.intl.t(
      this.isEdit
        ? 'receivable-invoices.customer-edition.exit-modal.description'
        : 'receivable-invoices.customer-creation.exit-modal.description'
    );
  }

  get cancelModalConfirmButtonLabel() {
    return this.intl.t(
      this.isEdit
        ? 'receivable-invoices.customer-edition.exit-modal.confirm.cta'
        : 'receivable-invoices.customer-creation.exit-modal.confirm.cta'
    );
  }

  get confirmButtonLabel() {
    return this.intl.t(
      this.isEdit
        ? 'receivable-invoices.customer-edition.save-changes.cta'
        : 'receivable-invoices.customer-creation.cta.add-client'
    );
  }

  @action onUpdateBillingAddressCountryCode(countryCode) {
    this.client.set('billingAddress', {
      ...this.client.billingAddress,
      countryCode,
    });
    this.updateLocaleWithCountryCode(countryCode);
  }

  @action onDeliveryAddressToggle() {
    this.isDeliveryAddressValidationEnabled = false;
  }

  /**
   * Side effect to update locale based on country code
   * @param {string} countryCode
   */
  @action updateLocaleWithCountryCode(countryCode) {
    let newCustomerLocaleCode =
      COUNTRIES_TO_LOCALES_ARRAY.find(
        ({ country_code }) => country_code === countryCode.toLowerCase()
      )?.code ?? DEFAULT_LOCALE_CODE;

    this.client.localeObject = this.intl.getLocales.find(
      ({ code }) => code === newCustomerLocaleCode
    );
  }

  @action cancel() {
    if (this.hasClientBeenChanged()) {
      this.triggerCancelModalTask.perform().catch(() => {
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
      });
    } else {
      if (!this.isEdit) this.segment.track('client-list_client-creation_canceled');
      this.args.close();
    }
  }

  hasClientBeenChanged() {
    if (!this.isEdit) {
      return this.client.hasClientValuesChanged();
    }
    return this.client.hasDirtyAttributes;
  }

  saveCustomerTask = dropTask(async () => {
    this.isValidationEnabled = true;
    this.isDeliveryAddressValidationEnabled = true;
    this.client.validate();

    if (this.client.validations.isValid) {
      try {
        this.client.email = this.client.email?.trim();

        if (this.isIndividual && this.client.billingAddress.countryCode !== 'IT') {
          this.client.tinNumber = undefined;
        }

        await this.client.save();
        if (this.isEdit) {
          this.segment.track('client-list_client-edition_confirmed');
          this.toastFlashMessages.toastSuccess(
            this.intl.t('receivable-invoices.customer-edition.toast-success')
          );
        } else {
          this.segment.track('client-list_client-creation_confirmed');
          this.toastFlashMessages.toastSuccess(
            this.intl.t('client-list.toasts.success.client-added')
          );
        }
        this.args.data.onClientSave?.(this.client);
        this.args.close();
        this.args.data.onSaveRedirect?.();
      } catch (error) {
        if (!this.isEdit) this.segment.track('client-list_client-creation_failed');
        if (this.client.errors.length === 0) {
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        }
      }
    }
  });

  triggerCancelModalTask = dropTask(async () => {
    await this.modals.open(
      'receivable-invoices/cancel-customer-modal',
      {
        title: this.cancelModalTitle,
        description: this.cancelModalDescription,
        cancel: this.intl.t('btn.cancel'),
        confirm: this.cancelModalConfirmButtonLabel,
        confirmTask: this.triggerCancelTask,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  triggerCancelTask = dropTask(async close => {
    if (!this.isEdit) this.segment.track('client-list_client-creation_canceled');
    this.client.rollbackAttributes();
    await close();
    this.args.close();
  });

  triggerDeleteClientModalTask = dropTask(async () => {
    this.segment.track('invoice-creation_client-edition_delete');
    await this.modals.open('receivable-invoices/delete-customer-modal', {
      title: this.intl.t('receivable-invoices.customer-deletion.delete-modal.title'),
      description: this.intl.t('receivable-invoices.customer-deletion.delete-modal.description', {
        clientName: this.client.name,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('receivable-invoices.customer-deletion.delete-modal.delete.cta'),
      confirmTask: this.deleteClientTask,
    });
  });

  deleteClientTask = dropTask(async close => {
    try {
      await this.client.destroyRecord();
      this.store.peekRecord('customer', this.client.id)?.unloadRecord();
      close();
      this.args.close();
      this.segment.track('invoice-creation_client-deletion_confirmed');
      this.toastFlashMessages.toastInfo(
        this.intl.t('receivable-invoices.customer-deletion.toast-success')
      );
    } catch {
      close();
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });
}
