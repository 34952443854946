export default {
  "row": "qkz",
  "icon": "qka",
  "cell": "qkH",
  "cell-date": "qkO",
  "cell-amount": "qkm",
  "cell-status": "qkp",
  "cell-content": "qkx",
  "align-right": "qkw"
};
