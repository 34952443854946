/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

export default class RemindersConfigurationEmailPreview extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service organizationManager;
}
