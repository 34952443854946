export default {
  "row": "cjy",
  "empty": "cjU",
  "active": "cjj",
  "animated": "cjW",
  "fadein-item": "cju",
  "cell": "cWS body-2",
  "cell-content": "cWc",
  "cell-amount": "cWq",
  "cell-content-amount": "cWZ body-1"
};
