export default {
  "referral-invite": "chp",
  "referral": "chx",
  "illustration": "chw",
  "steps": "chN",
  "step": "chv",
  "share-referral": "cho",
  "choice": "chn",
  "line": "chi",
  "email": "chA",
  "email-input": "chY",
  "input-field--error": "chh",
  "error-message": "chf",
  "external-link-icon": "chK"
};
