export default {
  "gap-32": "cvl",
  "fieldset": "cvX",
  "date-picker": "cvC",
  "with-performance-date": "cvk",
  "mt-4": "cvJ",
  "clients": "cvs",
  "dropdown-option": "cvF",
  "dropdown-content": "cvg",
  "select-client": "cvD",
  "client-option": "cvV",
  "icon-tooltip": "cvL",
  "payment-details": "cvT",
  "dates-container": "cvz",
  "automatic-number-label": "cva",
  "label": "cvH",
  "numbering-container": "cvO",
  "fix-width": "cvm",
  "customer-error": "cvp",
  "amount-text": "cvx",
  "header-text-field-container": "cvw",
  "header-text-field": "cvN",
  "header-text-field-close-button": "cvv",
  "mt-16": "cvo"
};
