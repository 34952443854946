export default {
  "qonto-pilot-modal": "cwR",
  "content": "cwQ",
  "header": "cwe",
  "transition-state-container": "cwB",
  "lottie": "cwE",
  "prompt-input": "cwd",
  "buttons": "cwI",
  "footer": "cwt caption",
  "divider": "cwM",
  "illustration": "cwP",
  "icon": "cwl",
  "quick-actions-container": "cwX",
  "action": "cwC body-2",
  "action-title": "cwk",
  "disabled": "cwJ",
  "action-tag": "cws caption-bold",
  "hotkey": "cwF body-1",
  "footer-action": "cwg",
  "grow": "cwD",
  "footer-feedback-link": "cwV",
  "buttons-container": "cwL",
  "transfer-details-container": "cwT",
  "error-container": "cwz",
  "option-iban": "cwa",
  "actions": "cwH",
  "title": "cwO",
  "group-title": "cwm caption-bold",
  "no-result": "cwp",
  "lottie-illustration": "cwx",
  "beta-badge": "cww",
  "hotkeys": "cwN",
  "supplier-invoices-dropzone": "cwv",
  "success-icon": "cwo",
  "error-icon": "cwn",
  "file-items": "cwi",
  "file-item": "cwA",
  "uploader-file": "cwY"
};
