export default {
  "card-details-container": "cWP",
  "title": "cWl caption-bold mb-16",
  "section": "cWX",
  "divider": "cWC",
  "top-divider": "cWk",
  "content": "cWJ body-2",
  "description": "cWs",
  "justify-end": "cWF",
  "icon": "cWg ml-8"
};
