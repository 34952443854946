export default {
  "header-cell": "cfk",
  "header-content": "cfJ caption-bold",
  "active": "cfs",
  "empty": "cfF",
  "request-type": "cfg",
  "date": "cfD",
  "requester": "cfV",
  "amount": "cfL",
  "header-status": "cfT"
};
