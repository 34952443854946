export default {
  "wrapper": "qRi",
  "header": "qRA mb-48",
  "form-title": "qRY title-2 mb-16",
  "form-subtitle": "qRh body-2",
  "form": "qRf",
  "section": "qRK mb-48",
  "section-title": "qRG title-3 mb-16",
  "form-footer": "qRr",
  "footer-btn": "qRb",
  "transfer-form": "qRy"
};
