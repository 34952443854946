export default {
  "wrapper": "qRD",
  "beneficiary": "qRV",
  "name": "qRL body-1",
  "beneficiary-email": "qRT body-2",
  "dropdown-icon": "qRz",
  "trusted": "qRa",
  "account-number": "qRH flex body-2",
  "account-icon": "qRO",
  "m-chip": "qRm",
  "dropdown": "qRp body-2",
  "delete-option": "qRx",
  "beneficiary-name": "qRw"
};
