/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

export default class EmailPreviewComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;

  get relatedQuoteNumber() {
    return this.args.invoice.quote?.get('number');
  }

  get currency() {
    return this.args.invoice.currency || 'EUR';
  }

  get showPaymentReferenceDisclaimer() {
    return (
      !this.args.isDraft &&
      !this.args.isQuote &&
      !this.args.invoice.directDebitEnabled &&
      !this.args.invoice.isEinvoice
    );
  }

  get footerDisclaimer() {
    return this.intl.t('receivable-invoices.invoicing-sending.preview.invoicing-disclaimer', {
      language: this.args.displayLanguage,
      htmlSafe: true,
    });
  }
}
