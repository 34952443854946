export default {
  "header": "cuI",
  "header-type": "cut",
  "close": "cuM",
  "close-icon": "cuP",
  "header-infos": "cul",
  "title": "cuX body-1",
  "declined": "cuC",
  "canceled": "cuk",
  "approved": "cuJ",
  "pending": "cus",
  "date": "cuF body-2",
  "container": "cug",
  "picto": "cuD",
  "picto-status": "cuV",
  "general": "cuL",
  "amount": "cuT",
  "counterparty": "cuz body-2",
  "initiator": "cua",
  "initiator-avatar": "cuH",
  "avatar-image": "cuO",
  "initiator-id": "cum",
  "name": "cup body-2",
  "role": "cux caption-bold",
  "infobox": "cuw",
  "account-infobox": "cuN cuw",
  "beneficiary-warning": "cuv caption-bold",
  "dates-wrapper": "cuo",
  "dates": "cun",
  "recurrence": "cui caption-bold",
  "arrow": "cuA",
  "notify-checkbox": "cuY"
};
