export default {
  "document": "cAE",
  "document-details": "cAd",
  "document-details-amount": "cAI body-2",
  "negative": "cAt",
  "positive": "cAM",
  "rejected": "cAP",
  "document-icon": "cAl",
  "document-details-link": "cAX body-link",
  "canceled": "cAC"
};
