export default {
  "header-cell": "cGP",
  "empty": "cGl",
  "header-type": "cGX",
  "header-requester": "cGC",
  "header-date": "cGk",
  "header-amount": "cGJ",
  "header-approval": "cGs",
  "header-content": "cGF caption-bold",
  "active": "cGg",
  "header-receipt": "cGD",
  "row-compact": "cGV",
  "row-sidebar": "cGL",
  "hidden": "cGT",
  "animated": "cGz",
  "fadein-item": "cGa",
  "animated-cell": "cGH"
};
