export default {
  "team-card": "qkG",
  "card-link": "qkr",
  "shadow": "qkb",
  "history-button": "qky",
  "cards-button": "qkU",
  "menu-item": "qkj",
  "subtitle": "qkW",
  "delete-team": "qku"
};
