export default {
  "container": "cac",
  "indicator-container": "caq",
  "transactions-container": "caZ",
  "indicator": "caR",
  "cashflow-container": "caQ",
  "cashflow": "cae",
  "donut-container": "caB",
  "donut": "caE"
};
