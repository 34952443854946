export default {
  "details-box": "cVB",
  "picto": "cVE",
  "picto-status": "cVd",
  "ghost": "cVI",
  "primary-info": "cVt",
  "date": "cVM",
  "account-infobox": "cVP",
  "declined-disclaimer": "cVl",
  "nrc-download": "cVX"
};
