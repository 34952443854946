export default {
  "form": "qMl",
  "form-section": "qMX",
  "form-title": "qMC title2",
  "divider": "qMk",
  "row": "qMJ",
  "col": "qMs",
  "actions": "qMF",
  "buttons": "qMg"
};
