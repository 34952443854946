export default {
  "container": "cnd",
  "text-content": "cnI",
  "text-wrapper": "cnt",
  "actions-container": "cnM",
  "illustration": "cnP",
  "feature-tour-link": "cnl",
  "link-illustration": "cnX",
  "lottie": "cnC"
};
