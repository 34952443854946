export default {
  "request-transfer-validation": "qRu",
  "account-selector": "qQS",
  "has-error": "qQc",
  "actions": "qQq",
  "action-btn": "qQZ",
  "warnings": "qQR",
  "top-border": "qQQ",
  "account-selector-subtitle": "qQe",
  "action-btn-right": "qQB",
  "funds-disclaimer": "qQE",
  "account-options": "qQd",
  "account-balance": "qQI",
  "request-multi-transfer-validation": "qQt",
  "wrapper": "qQM",
  "warning-message-row": "qQP",
  "multi-transfer-align": "qQl",
  "button-approve": "qQX",
  "button-approve-spinner": "qQC",
  "button-decline": "qQk"
};
