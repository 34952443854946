export default {
  "sidebar": "ciR",
  "body": "ciQ",
  "form": "cie",
  "form-section": "ciB",
  "form-title": "ciE title2",
  "row": "cid",
  "payment-details": "ciI",
  "col": "cit",
  "footer": "ciM",
  "footer-button": "ciP"
};
