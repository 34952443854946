export default {
  "refunds-total": "qtX",
  "switch-recurrence": "qtC",
  "flex-wrapper": "qtk",
  "promo-code": "qtJ",
  "promo-code-total": "qts",
  "others": "qtF",
  "others-total": "qtg",
  "subscription-detail": "qtD",
  "total-vat": "qtV",
  "fee-description": "qtL"
};
