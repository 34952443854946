/* import __COLOCATED_TEMPLATE__ from './sidepanel.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { getFullCurrencyName } from 'qonto/utils/currency';

export default class SupplierSidepanel extends Component {
  @service intl;
  @service modals;

  numberOfLoadingRelatedInvoices = new Array(5);

  get relatedInvoices() {
    return this.args.fetchRelatedInvoicesTask.lastSuccessful?.value || [];
  }

  get isLoading() {
    return this.args.fetchRelatedInvoicesTask.isRunning;
  }

  get supplierAddress() {
    let { supplier } = this.args;

    return [
      supplier.streetAddress,
      supplier.zipCode,
      supplier.city,
      supplier.countryCode && this.intl.formatCountry(supplier.countryCode),
    ]
      .filter(Boolean)
      .join(', ');
  }

  get supplierDefaultVatRate() {
    let { supplier } = this.args;

    if (!supplier.defaultVatRate) {
      return this.intl.t('supplier-invoices.supplier-creation.vat-rate.choice-chip.none');
    }
    return `${supplier.defaultVatRate}%`;
  }

  get supplierCurrency() {
    let { supplier } = this.args;

    return getFullCurrencyName({ currencyCode: supplier.currency, locale: this.intl.locale[0] });
  }

  @action archive() {
    let { supplier } = this.args;
    this.modals.open('suppliers/archive-popup', { supplier });
  }

  @action unarchive() {
    let { supplier } = this.args;
    this.modals.open('suppliers/unarchive-popup', { supplier });
  }
}
