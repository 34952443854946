export default {
  "wrapper": "chl",
  "tabs": "chX",
  "tabs-nav": "chC",
  "tabs-panel": "chk",
  "tabs-panel-wrapper": "chJ",
  "list-head": "chs",
  "list": "chF",
  "footer": "chg",
  "reward-amount": "chD",
  "rewarded": "chV",
  "registered": "chL",
  "disclaimer": "chT",
  "empty-state-wrapper": "chz",
  "empty-state": "cha",
  "illustration": "chH",
  "spinner": "chO",
  "total-earned": "chm"
};
