export default {
  "payment-details": "coG",
  "label-payment-conditions": "cor",
  "dropdown-content": "cob",
  "dropdown-option": "coy",
  "activate-sdd-container": "coU",
  "activate-sdd-header": "coj",
  "selector-toggle-sdd": "coW",
  "selector-toggle": "cou",
  "new-badge": "cnS",
  "new-badge-activation": "cnc"
};
