/* import __COLOCATED_TEMPLATE__ from './deactivated-account-blocker-modal.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

export default class AccountBlockerComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;

  animationReady = false;

  constructor() {
    super(...arguments);

    this.segment.track('organization_deactivated_displayed');
  }

  @action
  close() {
    this.args.close?.();
    this.args.data?.onCloseModal?.();
  }
}
