/* import __COLOCATED_TEMPLATE__ from './confirm-creation-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class ReceivableInvoicesConfirmCreationModalComponent extends Component {
  badge = Badge;

  @tracked isChecked = false;
  @tracked checkboxHasError = false;

  @action
  handledCheckbox() {
    this.isChecked = !this.isChecked;
    this.checkboxHasError = false;
  }

  confirmTask = dropTask(async () => {
    if (this.args.data.shouldDisplayCheckbox && !this.isChecked) {
      this.checkboxHasError = true;
      return;
    }

    await this.args.data.confirmTask.perform(this.args.close);
  });
}
