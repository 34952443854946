export default {
  "section-container": "qSZ",
  "disclaimer-section": "qSR",
  "trip-title": "qSQ caption-bold mb-16",
  "google-link-container": "qSe",
  "link-icon": "qSB",
  "trip-details": "qSE body-2",
  "trip-details-icon": "qSd",
  "trip-details-title": "qSI",
  "subtitle": "qSt caption-bold mb-16",
  "disclaimer": "qSM",
  "visualizer": "qSP"
};
