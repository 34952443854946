export default {
  "container": "cYZ",
  "content": "cYR",
  "input-row": "cYQ",
  "email-row": "cYe",
  "button-row": "cYB",
  "btn-edit": "cYE",
  "input-column": "cYd",
  "form-btn-row": "cYI",
  "border-bottom": "cYt",
  "avatar-input": "cYM"
};
