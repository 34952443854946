export default {
  "card": "cLd",
  "header": "cLI",
  "avatar": "cLt",
  "title": "cLM",
  "subtitle": "cLP",
  "label": "cLl",
  "item": "cLX body-2",
  "header-icon": "cLC",
  "dropdown-menu": "cLk",
  "menu-item": "cLJ body-2",
  "delete": "cLs"
};
