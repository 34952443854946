export default {
  "iban": "qZW",
  "checkbox": "qZu",
  "toggle-content": "qRS",
  "beneficiary-name": "qRc",
  "category": "qRq",
  "beneficiary-email": "qRZ",
  "beneficiary-form": "qRR",
  "beneficiary-email-container": "qRQ"
};
