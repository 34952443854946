export default {
  "details": "qtp",
  "details-header": "qtx",
  "details-content": "qtw",
  "details-footer": "qtN",
  "invoice-status-container": "qtv body-2",
  "invoice-payment-date": "qto",
  "overdue": "qtn",
  "details-list": "qti",
  "details-list-item": "qtA",
  "details-list-item-label": "qtY body-2",
  "details-list-item-value": "qth",
  "border-top": "qtf",
  "description": "qtK",
  "description-value": "qtG",
  "edit-button": "qtr body-2",
  "edit-icon": "qtb",
  "icon": "qty"
};
