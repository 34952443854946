export default {
  "fullscreen-modal": "cpd",
  "creation-form-modal": "cpI",
  "links-container": "cpt",
  "link-field": "cpM",
  "link": "cpP",
  "add-link": "cpl",
  "remove-link": "cpX",
  "flex-row": "cpC",
  "flex-item": "cpk",
  "unit-trigger": "cpJ",
  "dynamic-placeholders": "cps"
};
