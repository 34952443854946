export default {
  "insight-pill": "qMj",
  "insight-pill-figure": "qMW",
  "insight-pill-renderer": "qMu",
  "loading": "qPS",
  "counter": "qPc",
  "error": "qPq",
  "insight-pill-label": "qPZ",
  "insight-pill-subtitle": "qPR caption",
  "hidden": "qPQ",
  "missing-amounts-warning": "qPe",
  "missing-amounts": "qPB"
};
