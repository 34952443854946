export default {
  "item": "cAT",
  "details": "cAz",
  "flex": "cAa",
  "header": "cAH cAa",
  "flex-no-gap": "cAO cAa",
  "file-name": "cAm",
  "label": "cAp body-2",
  "self-icon": "cAx",
  "with-separator": "cAw",
  "dimmed": "cAN"
};
