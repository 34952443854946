export default {
  "insight-pill": "qMi",
  "interactive": "qMA",
  "focus": "qMY",
  "subtitle": "qMh caption",
  "hidden": "qMf",
  "missing-amounts-warning": "qMK",
  "totals": "qMG",
  "counter": "qMr title-3",
  "loading": "qMb",
  "error": "qMy",
  "title": "qMU title-4"
};
