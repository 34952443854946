export default {
  "header-cell": "cpy",
  "checkbox-cell": "cpU",
  "title-col": "cpj",
  "internal-note-col": "cpW",
  "unit-col": "cpu",
  "price-col": "cxS",
  "header-content": "cxc caption-bold",
  "active": "cxq",
  "empty": "cxZ"
};
