export default {
  "bullet": "czO",
  "slice-0": "czm",
  "slice-1": "czp",
  "slice-2": "czx",
  "slice-3": "czw",
  "slice-4": "czN",
  "slice-5": "czv",
  "slice-6": "czo",
  "slice-other": "czn",
  "slice-uncategorized": "czi",
  "label": "czA",
  "ellipsis": "czY",
  "rest": "czh",
  "previous-date-range": "czf caption"
};
