/* import __COLOCATED_TEMPLATE__ from './customer-card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, Disclaimer } from '@repo/design-system-kit';

import CURRENCIES from 'qonto/constants/currencies';
import { DEFAULT_LOCALE_PER_COUNTRY } from 'qonto/constants/receivable-invoice';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';

export default class ReceivableInvoicesCustomerCard extends Component {
  disclaimerInline = Disclaimer.Inline;

  badge = Badge;
  instructionalTooltip = InstructionalTooltip;

  @service intl;
  @service organizationManager;
  @service zendeskLocalization;

  @tracked isEinvoiceTooltipDismissed = false;

  get faqLink() {
    return this.intl.t('receivable-invoices.customer-card.einvoicing-disclaimer-faq.url', {
      faqUrl: this.zendeskLocalization.getLocalizedArticle('fr-einvoice'),
    });
  }

  get isOrganizationCountryDifferentThanCustomerCountry() {
    return (
      this.organizationManager.organization.legalCountry !==
      this.args.customer.get('billingAddress.countryCode')
    );
  }

  get billingStreetAddress() {
    let streetAddress = this.args.customer.get('billingAddress.streetAddress');
    streetAddress = this.hasSomeBillingAddressInfo ? streetAddress + ',' : streetAddress;
    return streetAddress;
  }

  get billingAddressInfo() {
    let zipCode = this.args.customer.get('billingAddress.zipCode');
    let city = this.args.customer.get('billingAddress.city');
    let countryCode = this.args.customer.get('billingAddress.countryCode');

    // return full string if all three are present
    if (this.hasAllBillingAddressInfo) {
      return `${zipCode} ${city}, ${countryCode}`;
    }

    // return the string with a space or comma depending
    // on the combination of at least two values
    if (zipCode && city) {
      return `${zipCode} ${city}`;
    }
    if (zipCode && countryCode) {
      return `${zipCode}, ${countryCode}`;
    }
    if (city && countryCode) {
      return `${city}, ${countryCode}`;
    }

    // this will either return an empty string or one of
    // these values if at least one exists
    return zipCode + city + countryCode;
  }

  get hasAllBillingAddressInfo() {
    return (
      this.args.customer.get('billingAddress.zipCode') &&
      this.args.customer.get('billingAddress.city') &&
      this.args.customer.get('billingAddress.countryCode')
    );
  }

  get hasSomeBillingAddressInfo() {
    return (
      this.args.customer.get('billingAddress.zipCode') ||
      this.args.customer.get('billingAddress.city') ||
      this.args.customer.get('billingAddress.countryCode')
    );
  }

  // 4th row for language and currency info is shown if one of them is not the default one
  get showLanguageAndCurrency() {
    let orgaCountry = this.organizationManager.organization.legalCountry;
    return (
      this.args.customer.get('locale') !== DEFAULT_LOCALE_PER_COUNTRY[orgaCountry] ||
      this.args.customer.get('currency') !== CURRENCIES.default ||
      this.isOrganizationCountryDifferentThanCustomerCountry ||
      this.args.showFullCustomerInfo
    );
  }

  @action
  handleEinvoiceTooltipClose() {
    this.isEinvoiceTooltipDismissed = true;
  }
}
