export default {
  "modal": "cAk",
  "action-container": "cAJ",
  "similar-label": "cAs",
  "info-icon": "cAF",
  "container": "cAg",
  "subtitle": "cAD caption-bold",
  "error": "cAV",
  "item-container": "cAL"
};
