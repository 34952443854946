export default {
  "item-wrapper": "qIn",
  "title-sub-wrapper": "qIi",
  "icon-title-wrapper": "qIA",
  "icon": "qIY",
  "icon-wrapper": "qIh",
  "title": "qIf body-1",
  "subtitle": "qIK body-2",
  "amount": "qIG body-1"
};
