export default {
  "content": "cUr",
  "balance": "cUb",
  "duration": "cUy",
  "green-text": "cUU",
  "balance-subtitle": "cUj",
  "balance-duration": "cUW",
  "footer": "cUu",
  "progress": "cjS",
  "progress-bar": "cjc",
  "progress-bar-text": "cjq",
  "progress-active": "cjZ"
};
