export default {
  "header": "qSl",
  "header-type": "qSX",
  "close": "qSC",
  "close-icon": "qSk",
  "header-infos": "qSJ",
  "title": "qSs body-1",
  "declined": "qSF",
  "canceled": "qSg",
  "approved": "qSD",
  "pending": "qSV",
  "date": "qSL body-2",
  "mileage-icon": "qST",
  "distance-subtitle": "qSz body-2",
  "link-icon": "qSa",
  "container": "qSH",
  "picto": "qSO",
  "general": "qSm",
  "amount": "qSp",
  "calculation-container": "qSx",
  "counterparty": "qSw body-2",
  "infobox": "qSN"
};
