export default {
  "account-container": "cHo",
  "badge": "cHn",
  "subtitle": "cHi title-4",
  "accounts-filter": "cHA",
  "account-filter-label": "cHY",
  "status": "cHh",
  "account-info": "cHf",
  "balance": "cHK",
  "revealed-balance": "cHG",
  "hidden-balance": "cHr"
};
