export default {
  "modal": "qBw",
  "container": "qBN",
  "form-container": "qBv",
  "preview-container": "qBo",
  "preview-content": "qBn",
  "switch-container": "qBi",
  "supplier-invoice-preview": "qBA",
  "header": "qBY",
  "footer": "qBh",
  "form-content": "qBf",
  "form-content-section": "qBK",
  "form-disclaimer": "qBG",
  "hidden": "qBr"
};
