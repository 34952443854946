export default {
  "wrapper": "qBI",
  "content": "qBt",
  "title": "qBM",
  "aside": "qBP",
  "description": "qBl",
  "faq-link": "qBX",
  "close-btn": "qBC",
  "container": "qBk",
  "mandate-field-list": "qBJ",
  "mandate-field-item": "qBs",
  "mandate-field-item__dot": "qBF",
  "mandate-field-item__text": "qBg"
};
