export default {
  "attachment-viewer": "cre",
  "hide-sidebar": "crB",
  "sidebar": "crE",
  "header": "crd",
  "header-top": "crI",
  "header-icon": "crt",
  "header-amount": "crM",
  "infobox": "crP",
  "vat": "crl",
  "validation": "crX"
};
