export default {
  "section-container": "cbt",
  "disclaimer-section": "cbM",
  "trip-title": "cbP caption-bold mb-16",
  "google-link-container": "cbl",
  "link-icon": "cbX",
  "trip-details": "cbC body-2",
  "trip-details-icon": "cbk",
  "trip-details-title": "cbJ",
  "subtitle": "cbs caption-bold mb-16",
  "disclaimer": "cbF",
  "visualizer": "cbg"
};
