export default {
  "mileage-validation": "qSY",
  "actions": "qSh",
  "action-btn": "qSf",
  "approve-btn": "qSK",
  "request-mileage-validation": "qSG",
  "account-selector": "qSr",
  "has-error": "qSb",
  "funds-disclaimer": "qSy",
  "account-options": "qSU",
  "account-balance": "qSj",
  "warnings": "qSW"
};
