export default {
  "attachment-viewer": "cWb",
  "hide-sidebar": "cWy",
  "sidebar": "cWU",
  "header": "cWj",
  "header-top": "cWW",
  "header-icon": "cWu",
  "header-amount": "cuS",
  "infobox": "cuc",
  "vat": "cuq",
  "validation": "cuZ"
};
