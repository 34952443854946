export default {
  "row": "cfz",
  "active": "cfa",
  "animated": "cfH",
  "fadein-item": "cfO",
  "cell": "cfm",
  "cell-content": "cfp",
  "row-sidebar": "cfx",
  "empty": "cfw",
  "cell-amount": "cfN",
  "subtitle": "cfv caption",
  "hidden": "cfo",
  "cell-approve": "cfn",
  "quick-actions": "cfi",
  "account-select": "cfA",
  "note": "cfY",
  "cell-content-amount": "cfh body-1",
  "cell-status": "cff"
};
