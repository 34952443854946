/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer, TextField } from '@repo/design-system-kit';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';

import ENV from 'qonto/config/environment';
import scrollIntoView from 'qonto/utils/scroll-into-view';

const MAX_RULES = 5;

export default class RemindersConfigurationFormPanel extends Component {
  @service userManager;
  @service store;
  @service intl;
  @service toastFlashMessages;
  @service segment;

  get contactEmail() {
    return this.userManager.currentUser.email;
  }

  get displaySendToError() {
    return this.args.displayErrors && !this.args.configuration.validations.attrs.sendTo.isValid;
  }

  isExpanded = rule => !this.args.collapsedRules?.includes(rule);

  @action
  updateSendTo(event) {
    this.args.configuration.sendTo = event.target.value;
  }

  @action
  toggleCopyToSelf() {
    this.args.configuration.copyToSelf = !this.args.configuration.copyToSelf;
  }

  @action
  addRule() {
    if (this.limitReached) {
      return;
    }

    this.segment.track('reminder_add-rule_clicked');

    this.store.createRecord('reminders-configuration/rule', {
      remindersConfiguration: this.args.configuration,
    });

    let newRuleIndex = this.args.configuration.visibleRules.length - 1;

    this.args.setFocusedRule(newRuleIndex);

    next(scrollIntoView, `[data-test-reminder-rule="${newRuleIndex}"]`);
  }

  @action
  deleteRule(index) {
    this.args.configuration.visibleRules[index].deleteRecord();
    this.toastFlashMessages.toastSuccess(
      this.intl.t('payment-reminders.toasts.success.delete-reminders', { count: 1 })
    );

    if (index < this.args.focusedRuleIndex) {
      this.args.setFocusedRule(this.args.focusedRuleIndex - 1);
    }
  }

  get limitReached() {
    let isLimitReached = this.args.configuration.visibleRules.length >= MAX_RULES;

    if (isLimitReached) {
      this.segment.track('reminder_limit-disclaimer_shown');
    }

    return isLimitReached;
  }

  get duration() {
    return ENV.environment === 'test' ? 0 : 150;
  }

  *transition({ insertedSprites, keptSprites, removedSprites }) {
    yield insertedSprites.forEach(fadeIn);
    yield keptSprites.forEach(move);
    yield removedSprites.forEach(fadeOut);
  }

  TextField = TextField;
  Disclaimer = Disclaimer;
}
